import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PanToolIcon from "@mui/icons-material/BackHand";
import CloseIcon from "@mui/icons-material/Close";
import { useKeycloak } from "@react-keycloak/web";
import React, { useState, useEffect } from "react";
import texts from "../constants/components/navbarText.js";
import logo from "../img/logo.png";
import logoFirmaCiudadana from "../img/navbar/marcaNormal.svg";
import perfilNormal from "../img/navbar/perfilNormal.svg";
import salirSesion from "../img/navbar/salirSesion.svg";
import Tabs from "./Tabs.js";
import axios from "axios";
import "./css/Navbar.css";

export default function MenuAppBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const handleLogout = () => {
    // Limpia el almacenamiento local
    localStorage.clear();

    // Limpia las cookies (esto eliminará todas las cookies, no solo las de Keycloak)
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    const redirectUri =
      process.env.REACT_APP_ENV === "prod"
        ? `https://firmaciudadana.pjm.gob.ar`
        : `https://dev-firmaciudadana.pjm.gob.ar`;

    // Llama a Keycloak para cerrar sesión
    keycloak.logout({
      redirectUri: redirectUri,
    });
  };
  useEffect(() => {
    // Verificar si ya se envió la solicitud en esta sesión
    const requestSent = sessionStorage.getItem("roleRequestSent");
    if (requestSent === "true") {
      setIsDisabled(true);
    }
  }, []);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAyudaPorTicket = async () => {
    if (!keycloak?.token || !keycloak?.idTokenParsed) {
      setMessage("No se pudo obtener la información del usuario.");
      setOpenSnackbar(true);
      return;
    }

    const { given_name, family_name, email } = keycloak.idTokenParsed;

    const headers = {
      Authorization: `Bearer ${keycloak.token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AYUDA}`,
        {
          name: given_name,
          surname: family_name,
          email: email,
        },
        { headers, timeout: 20000 }
      );
      console.log("response ayuda", response);
      setMessage("Solicitud de ayuda enviada con éxito.");
      setOpenSnackbar(true);
    } catch (error) {
      setMessage("Error al enviar la solicitud de ayuda.");
      setOpenSnackbar(true);

      console.error("Error en la solicitud de ayuda:", error);
    }
  };

  let menuItemText;
  let rolText;
  if (props.userRole === "publicador") {
    menuItemText = texts.roles.aFirmar;
    rolText = "Publicador";
  } else if (props.userRole === "firmante") {
    menuItemText = texts.roles.aPublicar;
    rolText = "Firmante";
  } else if (props.userRole === "firmantepublicador") {
    menuItemText = texts.roles.noRolASolicitar;
    rolText = "Publicador y Firmante";
  } else {
    menuItemText = texts.roles.noRol;
    rolText = "";
  }
  const handleOpenDialog = () => {
    if (!keycloak?.token || !keycloak?.idTokenParsed) {
      setMessage("No se pudo obtener la información del usuario.");
      setOpenSnackbar(true);
      return;
    }

    const { given_name, family_name, cuil } = keycloak.idTokenParsed;
    let roleText = "";
    if (props.userRole === "publicador") {
      roleText = "Firmante";
    } else if (props.userRole === "firmante") {
      roleText = "Publicador";
    } else if (props.userRole === "firmantepublicador") {
      setMessage("Usted ya posee todos los roles disponibles.");
      setOpenSnackbar(true);
      return;
    } else {
      setMessage("Rol desconocido, no se puede procesar la solicitud.");
      setOpenSnackbar(true);
      return;
    }
    setDialogText(
      `Usted, ${given_name} ${family_name}, de CUIL ${cuil}, solicita el rol de ${roleText}. ¿Desea confirmar?`
    );
    setOpenDialog(true);
  };
  const handleMenuFormClick = async () => {
    setOpenDialog(false);
    setLoading(true);
    if (!keycloak?.token || !keycloak?.idTokenParsed) {
      setMessage("No se pudo obtener la información del usuario.");
      setOpenSnackbar(true);
      return;
    }

    const { email, name, validation_level, cuil, resource_access } =
      keycloak.idTokenParsed;

    // Obtener los roles desde "firma-ciudadana"
    const roles = resource_access?.["firma-ciudadana"]?.roles || [];

    const headers = {
      Authorization: `Bearer ${keycloak.token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FORMULARIO_ROL}`,
        { cuil, email, name, validation_level, roles },
        { headers, timeout: 20000 }
      );
      console.log("response form", response);
      setMessage("Solicitud enviada con éxito.");
      setOpenSnackbar(true);
      sessionStorage.setItem("roleRequestSent", "true");
      setIsDisabled(true);
      setLoading(false);
    } catch (error) {
      setMessage("Error al enviar la solicitud.");
      setOpenSnackbar(true);
      setLoading(false);
      console.error("Error al realizar la solicitud POST:", error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        height: "123px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        className="containerLogoPoderJudicial"
        onClick={() => {
          const url =
            process.env.REACT_APP_ENV === "prod"
              ? "https://docs.firmaciudadana.pjm.gob.ar/"
              : "https://dev-docs.firmaciudadana.pjm.gob.ar/";
          window.location.href = url;
        }}
        style={{ cursor: "pointer" }}
      >
        <img
          alt="logoFirmaCiudadana"
          src={logoFirmaCiudadana}
          className="logoNavbar"
        />
        <img alt="Logo" src={logo} className="logoNavbarPoderJudicial" />
      </div>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          minHeight: "48px !important",
          padding: "0 !important",
          backgroundColor: "white",
          width: "95%",
        }}
        className="ToolbarNavbar"
      >
        <Box className="boxTabs">
          <Tabs
            onTabChange={props.onTabChange}
            userRole={props.userRole}
            cuil={props.cuil}
          />
        </Box>
        <IconButton
          sx={{
            backgroundColor: "#FAFAFA",
            borderRadius: " 25px 25px 0 0",
            marginRight: "20px",
          }}
          className="IconButtonNavbar"
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
        >
          <img src={perfilNormal} alt="logoPerfil" className="logoPerfil" />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={handleProfileMenuClose}
        >
          <MenuItem>
            <Typography variant="body1" className="UserBoxBienvenidoTextNavbar">
              {texts.bienvenido} {keycloak.idTokenParsed?.name}!
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1" className="UserBoxNormalTextNavbar">
              {rolText && `${texts.rolDe} ${rolText}`}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={handleOpenDialog}
            disabled={props.userRole === "firmantepublicador" || isDisabled}
          >
            <Typography className="UserBoxNormalTextNavbar" variant="body1">
              {menuItemText}
            </Typography>
          </MenuItem>
          <MenuItem disabled onClick={handleAyudaPorTicket}>
            <IconButton
              edge="start"
              color="inherit"
              sx={{ paddingLeft: 1.5, "& svg": { fontSize: 18 } }}
              disableRipple
            >
              <PanToolIcon />
            </IconButton>
            <Typography className="UserBoxNormalTextNavbar" variant="body1">
              Generar ticket de ayuda
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              const url =
                process.env.REACT_APP_ENV === "prod"
                  ? "https://docs.firmaciudadana.pjm.gob.ar/"
                  : "https://dev-docs.firmaciudadana.pjm.gob.ar/";
              window.location.href = url;
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              sx={{ paddingLeft: 1.5, "& svg": { fontSize: 18 } }}
              disableRipple
            >
              <HelpOutlineIcon />
            </IconButton>
            <Typography className="UserBoxNormalTextNavbar" variant="body1">
              Ayuda online
            </Typography>
          </MenuItem>
          <MenuItem className="UserBoxButtonNavbar">
            <Button
              variant="contained"
              className="buttonOutNavbar"
              onClick={handleLogout}
              endIcon={
                <img
                  src={salirSesion}
                  alt="salirSesion"
                  className="salirSesion"
                />
              }
            >
              {texts.buttonOut}
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
      <Dialog
        open={openDialog}
        sx={{ width: "100%" }}
        PaperProps={{ sx: { width: "100%" } }}
      >
        <IconButton
          edge="end"
          onClick={() => setOpenDialog(false)}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            boxShadow: "none", // Desactiva la sombra
            "&:hover": {
              backgroundColor: "transparent", // Evita que cambie de color al pasar el mouse
            },
            "&:focus": {
              outline: "none", // Quita el contorno del enfoque
            },
          }}
        >
          <CloseIcon sx={{ color: "gray" }} />
        </IconButton>
        <DialogTitle>Confirmación de solicitud</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontFamily: "robotoMedium", color: "#316094" }}
            onClick={() => setOpenDialog(false)}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            sx={{ fontFamily: "robotoMedium", color: "#316094" }}
            onClick={handleMenuFormClick}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "#316094" }} />
            ) : (
              "Confirmar"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={message.includes("éxito") ? "success" : "error"}
          sx={{ fontFamily: "robotoMedium" }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </AppBar>
  );
}
